import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/showledger.css';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;

  return (
    <input
      value={globalFilter || ''}
      onChange={e => setGlobalFilter(e.target.value || undefined)}
      placeholder={`Search ${count} records...`}
    />
  );
}

const PartyTable = () => {
  const [combinedParties, setCombinedParties] = useState([]);
  const navigate = useNavigate(); // Initialize navigate for navigation

  useEffect(() => {
    const fetchParties = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/parties', {
          headers: { 'x-client-email': email }
        });
        const businessParties = response.data.map(party => ({ ...party, type: 'Business' }));
        return businessParties;
      } catch (error) {
        console.error('Error fetching parties:', error);
        return [];
      }
    };

    const fetchPersonalParties = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/personalparties', {
          headers: { 'x-client-email': email }
        });
        const personalParties = response.data.map(party => ({ ...party, type: 'Personal' }));
        return personalParties;
      } catch (error) {
        console.error('Error fetching personal parties:', error);
        return [];
      }
    };

    const fetchAllParties = async () => {
      const businessParties = await fetchParties();
      const personalParties = await fetchPersonalParties();
      setCombinedParties([...businessParties, ...personalParties]);
    };

    fetchAllParties();
  }, []);

const handleEditClick = (party) => {
  // Check the type of the selected party and navigate accordingly
  if (party.type === 'Business') {
    navigate('../edit-ledger-business/', { state: { party } });
  } else if (party.type === 'Personal') {
    navigate('../edit-ledger-personal/', { state: { party } });
  } else {
    toast.error('Invalid party type.');
  }
};

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: 'Mobile',
        accessor: 'contact',
      },
      {
        Header: 'GSTIN',
        accessor: 'gstin',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Action', // New action column
        Cell: ({ row }) => (
          <button onClick={() => handleEditClick(row.original)}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: combinedParties,
      initialState: { pageIndex: 0, pageSize: 5 } 
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter
  } = tableInstance;

  return (
    <div className='show-ledger'>
      <h2 className='ledheading'>Party List</h2>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()} className='show-ledger-table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false}
      />
    </div>
  );
};

export default PartyTable;
