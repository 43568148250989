import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/cashReceiptPreview.css';
import numberToWords from '@jstb/num-to-words-indian';
import { toast } from 'react-toastify';
import { faPrint,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const CashReceiptPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { billNo } = location.state || {};
  const [companyDetails, setCompanyDetails] = useState({});
  const [data, setData] = useState(null);
  const [dynamicData, setDynamicData] = useState([]);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/companydetails', {
          headers: { 'email': email }
        });
        setCompanyDetails(response.data);
      } catch (error) {
        console.error('Error fetching company details:', error);
        toast.error('Error fetching company details.');
      }
    };

    fetchClient();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await axios.get('https://tms-server1.azurewebsites.net/getcashreceiptpreview', {
          params: { billNo, clientemail: email }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching cash receipt details:', error);
        toast.error('Error fetching cash receipt details.');
      }
    };

    fetchData();
  }, [billNo]);

  useEffect(() => {
    const fetchDynamicData = async () => {
      try {
        const email = sessionStorage.getItem('email');
        console.log(billNo);
        
        const response = await axios.get('https://tms-server1.azurewebsites.net/getCashReceiptdynamicdata', {
          params: { additionalreceiptno:billNo, clientemail: email }
        });
        setDynamicData(response.data);
        console.log(response.data);
        
      } catch (error) {
        console.error('Error fetching dynamic data:', error);
        toast.error('Error fetching dynamic data.');
      }
    };

    fetchDynamicData();
  }, [billNo]);

  if (!data) {
    return <div>Loading...</div>;
  }

  // Ensure data[0] exists and contains the necessary fields
  const biltydateformattedDate = data[0]?.billtyDate ? moment(data[0]?.billtyDate).format('DD-MMMM-YYYY') : '';
  const cashdateformattedDate = data[0]?.cashreceiptdate ? moment(data[0]?.cashreceiptdate).format('DD-MMMM-YYYY') : '';

  // Function to convert grossTotal to words
  const grossTotalWords = numberToWords(data[0]?.grossTotal) + ' rupees only';
  console.log(grossTotalWords);

  // Calculate the total gross amount from dynamic data
  const totalGross = dynamicData.reduce((sum, detail) => sum + parseFloat(detail.grossTotal || 0), 0);
  const grossTotalWordsDynamic = numberToWords(totalGross) + ' rupees only';

  return (
    <div className="container">
      <div className="head-cashreceipt">
        <div>
          <h2 className='headercompany'>
          <button  className='no-print savebuttonpreview' onClick={() => { navigate('/clientDashboard/showCashReceipt') }}><FontAwesomeIcon icon={faArrowLeft} size="lg"/> Back</button>
         
            {companyDetails?.company_name}
            <button className='no-print previewbtn' onClick={() => window.print()}>
              <FontAwesomeIcon icon={faPrint} size="lg" /> Print
            </button>
          </h2>
          <p className='upperdata'>Head Office: {companyDetails?.ho_address}, {companyDetails?.ho_city}, {companyDetails?.ho_state}, {companyDetails?.ho_pin_code}</p>
          {companyDetails.bo_address && companyDetails.bo_city && companyDetails.bo_state && companyDetails.bo_pin_code && (
            <p className='upperdata'>Branch Office: {companyDetails.bo_address}, {companyDetails.bo_city}, {companyDetails.bo_state}, {companyDetails.bo_pin_code}</p>
          )}
          <p className='upperdata contactinfo'>Contact: {companyDetails?.contact_no}, Email: {companyDetails?.email}</p>
        </div>
        <div className="top-cashreceipt">
          <p className='upperdata'>GST: {companyDetails.gstin}</p>
          <h4 className='upperdata'>RECEIPT</h4>
        </div>
      </div>
      <form>
        <div className="row-cashreceipt row1-cashreceipt">
          <p className='receiptno'>Receipt Number: <span className='rsno'>{data[0]?.cashreceiptno || "-"}</span></p>
          <p className='cashreceiptdate'>Date: <span className='cashspan'>{cashdateformattedDate}</span></p>
        </div>
        <div className="row-cashreceipt row1-cashreceipt">
          <p className="cashpara">
            Received with thanks from Mr./Mrs/M/s <span className="cashspan">{data[0]?.receivedFrom || "-"}</span> Against
            Bill Number <span className="cashspan">{data[0]?.billNo || "-"}</span> through {' '}
            <span className="cashspan">{data[0]?.cashOrChequeNo === 'cash' || data[0]?.cashOrChequeNo === 'Cash' ? 'Cash' : `Cheque Number ${data[0]?.cashOrChequeNo}`}</span>,
            for movement of Goods for the following:
        
          </p>
        </div>
        

        {/* Dynamic Table */}
        <div className="dynamic-table">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>GR No.</th>
                <th>GR Date</th>
                <th>From</th>
                <th>To</th>
                <th>Gross Total</th>
              </tr>
            </thead>
            <tbody>
              {dynamicData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.grNo}</td>
                  <td>{moment(item.biltyDate).format('DD-MMM-YYYY') || "-"}</td>
                  <td>{item.billFrom || "-"}</td>
                  <td>{item.billTo || "-"}</td>
                  <td>{item.grossTotal || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>

          Branch <span className="cashspan"> {data[0]?.cashreceiptbranch || "-"}</span>
          </p>
           
          <p className='rupee'>
          <svg className="svg-biltycashrepreviewceipt" fill="#000000" width="20px" height="20px" viewBox="-96 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M308 96c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12H12C5.373 32 0 37.373 0 44v44.748c0 6.627 5.373 12 12 12h85.28c27.308 0 48.261 9.958 60.97 27.252H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h158.757c-6.217 36.086-32.961 58.632-74.757 58.632H12c-6.627 0-12 5.373-12 12v53.012c0 3.349 1.4 6.546 3.861 8.818l165.052 152.356a12.001 12.001 0 0 0 8.139 3.182h82.562c10.924 0 16.166-13.408 8.139-20.818L116.871 319.906c76.499-2.34 131.144-53.395 138.318-127.906p08c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-58.69c-3.486-11.541-8.28-22.246-14.252-32p08z"></path>
          </svg>
          <input type="number" className="input-cashreceipt rsinput" value={data[0]?.grossTotal || "-"} readOnly />
          <input type="text" className="input-cashreceipt rsinputwords" value={grossTotalWords} readOnly />
        </p>
        </div>
      </form>
      <p className='authorisedsignature'>
      <span className="cashspan"> For {companyDetails?.company_name}</span>
      </p>
      <p className='authorisedsignature'>
      <span className="cashspan"> Authorised Signatory</span>
      </p>
    </div>
  );
};

export default CashReceiptPreview;
