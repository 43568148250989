import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/showClients.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const ShowClients = () => {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://tms-server1.azurewebsites.net/getClients');
      // Ensure the response data is an array of arrays
      if (Array.isArray(response.data) && response.data.length > 0 && Array.isArray(response.data[0])) {
        setClients(response.data[0]); // Access the first array which contains the clients
      } else {
        console.error('Unexpected response format:', response.data);
        toast.error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      toast.error('Error fetching clients');
    }
  };

  const handleEdit = (id) => {
    navigate(`/adminDashboard/edit-client/${id}`);
  };

  if (!Array.isArray(clients) || clients.length === 0) {
    console.log(clients);
    return <div>Loading...</div>;
  }

  return (
    <div className="show-clients">
      <h2>Show Clients</h2>
      <table className='show-clients-table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Company Name</th>
            <th>Email Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id}>
              <td>{client.owner_name}</td>
              <td>{client.company_name}</td>
              <td>{client.email}</td>
              <td>
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Edit Client"
                  onClick={() => handleEdit(client.id)}
                  style={{ cursor: 'pointer' }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false} // Remove close button
      />
    </div>
  );
};

export default ShowClients;
