import React, { useState } from 'react';
import '../styles/addLedgerOverlay.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import LedgerForm from './ledgerForm';
import PersonalLedgerForm from './personalUser';

const AddLedgerOverlay = ({ isVisible, onClose }) => {
  const [activeTab, setActiveTab] = useState('form1');

  const renderForm = () => {
    if (activeTab === 'form1') {
      return <PersonalLedgerForm />;
    } else if (activeTab === 'form2') {
      return <LedgerForm />;
    }
  };

  return (
    <div className={`ledger-overlay ${isVisible ? 'show' : ''}`}>
      <div className="ledger-overlay-content">
        <button type="button" className="ledger-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="tab-navigation">
          <button className={activeTab === 'form1' ? 'active' : ''} onClick={() => setActiveTab('form1')}>Personal </button>
          <span className="separator">|</span>
          <button className={activeTab === 'form2' ? 'active' : ''} onClick={() => setActiveTab('form2')}>Business </button>
        </div>
        {renderForm()}
      </div>
    </div>
  );
};

export default AddLedgerOverlay;
